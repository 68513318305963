import { createSlice } from '@reduxjs/toolkit';
import { registerUser, userLogin } from './authActions';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  loading: false,
  userInfo: null,
  token: cookies.get('token') || null,
  refresh: cookies.get('refresh') || null,
  error: null,
  success: false,
  isInitialized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    initializeAuth: (state) => {
      state.isInitialized = true;
    },
    logout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.refresh = null;
      state.error = null;
      state.success = false;
      state.isInitialized = false;

      // Çerezleri temizle
      cookies.remove('token', { path: '/', domain: 'eronware.com' });
      cookies.remove('refresh', { path: '/', domain: 'eronware.com' });
    },
  },
  extraReducers: (builder) => {
    // login user
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.token = payload.data.token;
      state.refresh = payload.data.refresh;
      state.error = null;

      // Çerezleri ayarla
      cookies.set('token', payload.data.token, { path: '/', maxAge: 60 * 60 * 24 * 365, domain: '.eronware.com' });
      cookies.set('refresh', payload.data.refresh, { path: '/', maxAge: 60 * 60 * 24 * 365, domain: '.eronware.com' });
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // register user
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.success = true; // registration successful
      state.error = null;
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { setToken, setRefresh, initializeAuth, logout } = authSlice.actions;
export default authSlice.reducer;
