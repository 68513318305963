import React from 'react';
import { Container, Typography, Paper, ThemeProvider, createTheme } from '@mui/material';
import './styles.css';

// Custom theme for consistent design
const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff',
      contrastText: '#fff',
    },
    background: {
      default: '#f4f6f8',
      paper: '#fff',
    },
    text: {
      primary: '#333',
      secondary: '#666',
    },
  },
  typography: {
    h3: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '2rem',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1rem',
      marginBottom: '1.5rem',
    },
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 8,
});

function Pricing() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='sm' className='pricing-container'>
        <Typography variant='h3' gutterBottom align='center' className='pricing-title'>
          Pricing Plan
        </Typography>
        <Paper elevation={3} className='styled-paper'>
          <Typography variant='h5' component='h3'>
            Free Plan
          </Typography>
          <Typography variant='body1' gutterBottom>
            Enjoy all the features of our Amazon Arbitrage Extension for free until 01/01/2025.
          </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default Pricing;
